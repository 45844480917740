// @flow

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CORPORATE_HOME_PAGE, IMAGE_PATH } from '../config/config';
import { Nav } from './nav';

const styles = (theme) => createStyles({
  notFound : {
    margin: '0 auto',
    padding: '30px',
    textAlign: 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  width: 'xs' | null,
}

type State = {};

class NotFoundPageComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {

  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Nav
          content={
            <a href={CORPORATE_HOME_PAGE}>
              <img src={`${IMAGE_PATH}/logo/team-engine-logo-with-name-colored.png`} height={this.props.width === 'xs' ? '30' : '50'} alt="Home"/>
            </a>
          }
        />
        <Typography className={classes.notFound} variant="title">
          Not Found
        </Typography>
      </div>
    );
  }
}

export const NotFoundPage = withStyles(styles)(NotFoundPageComponent);
