export const isProd = process.env.NODE_ENV === 'production';

export const IMAGE_PATH = isProd ?
  'https://images.expeditiondata.com' :
  'https://images-dev.thehiringengine.com';

export const APPLY_DOMAIN = isProd ?
  'https://apply.teamengine.io' :
  'https://local.apply.expeditiondata.com:3000';

export const APPLY_API_DOMAIN = isProd ?
  'https://api.thehiringengine.com/api' :
  'https://local.apply.expeditiondata.com:8000/api';

// Since we don't have a local dev version of the home page, we'll instead route to the employer home page,
// so we aren't accidentally accessing prod resources while working locally
export const CORPORATE_HOME_PAGE = isProd ?
  'https://www.teamengine.io' :
  'https://local.employer.expeditiondata.com:8443';

// Since we don't have a local dev version of the get team engine page, we'll instead route to the employer home page,
// so we aren't accidentally accessing prod resources while working locally
export const GET_TEAM_ENGINE_PAGE = isProd ?
  'https://get.teamengine.io/powered-by-te/' :
  'https://local.employer.expeditiondata.com:8443'
