// @flow

import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography/index';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import type { Org, Job } from '../types';
import { CustomerApiClient } from '../api/customer-api-client';
import { CORPORATE_HOME_PAGE, IMAGE_PATH } from '../config/config';
import { JobCard } from './job-card';
import { Nav } from './nav';
import { Redirect } from 'react-router';
import { WebRoutes } from '../web-routes';
import { getCustomerLogoPath } from '../utils/customer-utils';

const styles = (theme) => createStyles({
  container: {
    padding: '20px',
    margin: '0 auto',
    maxWidth: '800px',
  },
  careers: {
    marginLeft: '10px',
  },
  locationContainer: {
    margin: '20px 0',
  },
  noJobs: {
    margin: '0 auto',
  },
});

interface Props extends WithStyles<typeof styles> {
  orgKey: string,
}

type JobMap = {[location: string]: Array<Job>};

type State = {
  jobs: ?JobMap,
  org: ?Org,
  orgSuccessful: ?boolean,
  jobsSuccessful: ?boolean,
};

function groupBy(list, keyGetter) {
  const map = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map[key];
    if (!collection) {
      map[key]= [item];
    } else {
      collection.push(item);
    }
  });
  return map;
}

class CareersPageComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      jobs: null,
      org: null,
      orgSuccessful: null,
      jobsSuccessful: null,
    };
  }

  componentDidMount = async () => {
    const client = new CustomerApiClient(this.props.orgKey);
    const orgPromise = client.get();
    const jobsPromise = client.getJobs();

    const [org, jobs] = await Promise.all([orgPromise, jobsPromise]);
    const orgOk = org.status === 200;
    const jobsOk = jobs.status === 200;
    if (orgOk) {
      document.title = `Careers at ${org.data.name}`;
    } else {
      document.title = `Team Engine`;
    }
    this.setState({
      orgSuccessful: orgOk,
      jobsSuccessful: jobsOk,
      org: orgOk ? org.data : null,
      jobs: jobsOk ? groupBy(jobs.data, j => `${j.city}, ${j.state}`) : null,
    });
  };

  renderJobs = (org: Org, jobs: JobMap) => {
    const { classes } = this.props;
    return Object.keys(jobs).map(l => (
      <React.Fragment key={l}>
        <Grid item container xs={12} className={classes.locationContainer}>
          <Grid item xs={12}>
            <Typography variant="title">
              {l}
            </Typography>
          </Grid>
          {jobs[l].map(j => (
            <Grid item key={j.job_hash}>
              <JobCard job={j} org={org} />
            </Grid>
          ))}
          <Divider />
        </Grid>
      </React.Fragment>
    ))
  };

  render() {
    const { classes } = this.props;
    const { jobs, org, jobsSuccessful, orgSuccessful } = this.state;

    if (jobsSuccessful === null || orgSuccessful === null) {
      return (
        <Typography variant="display3">
          Loading...
        </Typography>
      )
    }

    if (!orgSuccessful) {
      return (
        <Redirect to={WebRoutes.NOT_FOUND} />
      )
    }

    return (
      <div>
        {orgSuccessful && org ?
          <Nav
            content={
              <React.Fragment>
                <a href={org.website}>
                  <img src={getCustomerLogoPath(org)} height={this.props.width === 'xs' ? '30' : '50'} alt="Home"/>
                </a>
                <Typography variant="title" className={classes.careers}>
                  Careers at {org.name}
                </Typography>
              </React.Fragment>
            }
          /> :
          <Nav
            content={
              <a href={CORPORATE_HOME_PAGE}>
                <img src={`${IMAGE_PATH}/logo/team-engine-logo-with-name-colored.png`} height={this.props.width === 'xs' ? '30' : '50'} alt="Home"/>
              </a>
            }
          />
        }
        <Grid container className={classes.container} spacing={16}>
          {!jobsSuccessful || !jobs || !org || Object.keys(jobs).length === 0 ?
            <Grid item container>
              <Typography variant="display1" className={classes.noJobs}>
                No open jobs right now. Please check back later.
              </Typography>
            </Grid>
          :
            this.renderJobs(org, jobs)
          }
        </Grid>
      </div>
    );
  }
}

export const CareersPage = withStyles(styles)(CareersPageComponent);
