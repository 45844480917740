// @flow

import React from 'react';
import type { Job, Org } from '../types';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { APPLY_DOMAIN, IMAGE_PATH } from '../config/config';
import { getCustomerLogoPath } from '../utils/customer-utils';

const styles = (theme) => createStyles({
  card: {
    width: 345,
    display: 'inline-block',
    margin: '10px',
  },
  mediaContainer: {
    position: 'relative',
    height: 140,
  },
  media: {
    height: 140,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  title: {
    ...theme.typography.h6,
    fontWeight: 800,
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: 140,
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
  },
  jobTitle: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white',
    fontWeight: 800,
  },
  compensationSubtitle: {
    position: 'relative',
    color: 'white',
    fontWeight: 400,
    fontSize: 'medium'
  },
  contentNoImage: {
    paddingTop: 0,
    minWidth: '345px',
  },
});

interface Props extends WithStyles<typeof styles> {
  job: Job,
  org: Org,
}

type State = {};

class JobCardComponent extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {}
  }

  openJob = () => {
    window.open(`${APPLY_DOMAIN}/apply/${this.props.job.job_hash}/v/S?utm_source=careers_page`, '_blank');
  };

  stripHTML = (str: string) => {
    return str.replace(/(<([^>]+)>)/gi, "")
  }

  formatCurrency = (amount: ?number, hasCents: boolean) => {
    if (!amount) return null;

    let [dollars, cents] = amount.toFixed(hasCents ? 2 : 0).split('.');
    if (dollars.length > 3){
      let dollarsFormatted = '';
      let count = 1;
      for (let i = dollars.length - 1; i >= 0; i--) {
        dollarsFormatted = dollars[i] + dollarsFormatted;
        if ((count % 3 === 0) && (count !== dollars.length)) {
          dollarsFormatted = ',' + dollarsFormatted;
        }

        count += 1;
      }
      dollars = dollarsFormatted;
    }

    return cents ? `${dollars}.${cents}` : `${dollars}`;
  };

  compensationString = (compensationLow: ?number, compensationHigh: ?number, compensationType: string, t: (value: string) => string) => {
    let compensationFormatted = '';
    const hasCents = this.doesCompensationHaveCents(compensationLow, compensationHigh)

    const high = this.formatCurrency(compensationHigh, hasCents);
    const low = this.formatCurrency(compensationLow, hasCents);

    const adornment = compensationType === 'HOURLY' ? '/hr' : '/yr'

    if (low && high && (low !== high)) {
      if (parseFloat(low) > parseFloat(high)) {
        compensationFormatted = `$${high}${adornment} - $${low}${adornment}`
      } else {
        compensationFormatted = `$${low}${adornment} - $${high}${adornment}`
      }
    } else if (low) {
      // also covers low === high
      compensationFormatted = `$${low}${adornment}`;
    } else if (high) {
      compensationFormatted = `$${high}${adornment}`;
    }

    return compensationFormatted;
  };

  doesCompensationHaveCents = (low: number, high: number) => {
    const doesLowHaveCents = low ? !Number.isInteger(low) : false;
    const doesHighHaveCents = high ? !Number.isInteger(high) : false;

    return doesLowHaveCents || doesHighHaveCents;
  }

  render() {
    const { classes, job, org } = this.props;

    return (
      <Card className={classes.card}>
        <CardActionArea
          onClick={this.openJob}
        >
          {job.imagePath === null &&
            <CardHeader
              title={job.title}
              className={classes.title}
              disableTypography
            />
          }
          {job.imagePath !== null &&
            <div className={classes.mediaContainer}>
              <CardMedia
                className={classes.media}
                image={job.imagePath ? `${IMAGE_PATH}/${job.imagePath}` : getCustomerLogoPath(org)}
                title={job.title}
              />
              <div className={classes.overlay} />
              <Typography className={classes.jobTitle} variant="h6">
                {job.title} 
                { job.shouldPublishCompensation && (job.compensationLow || job.compensationHigh) &&
                  <Typography component="span" variant="subtitle1" className={classes.compensationSubtitle}>
                    {this.compensationString(job.compensationLow, job.compensationHigh, job.compensationType)}
                  </Typography>
                }
              </Typography>
            </div>
          }

            <CardContent className={job.imagePath === null ? classes.contentNoImage : null}>
              {job.shortDescription && (
                <Typography component="p" dangerouslySetInnerHTML={{
                  __html: job.shortDescription,
                }} />
              )}
              {!job.shortDescription && job.description && (
                <Typography component="p" dangerouslySetInnerHTML={{
                  __html: this.stripHTML(job.description).substring(0, 200).trim() + '...',
                }} />
              )}
              {!job.shortDescription && !job.description && <Typography>Click here to apply!</Typography>}
            </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

export const JobCard = withStyles(styles)(JobCardComponent);
