// @flow

import axios from 'axios';
import { APPLY_API_DOMAIN } from '../config/config';

export const Api = {
  get: (url: string) =>
    axios.get(`${APPLY_API_DOMAIN}${url}`, {
      /*
      Don't throw an error if we get a non-200. We'll handle it separately
       */
      validateStatus: () => true,
    }),

  post: (url: string, body: any) =>
    axios.post(`${APPLY_API_DOMAIN}${url}`, body, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      /*
      Don't throw an error if we get a non-200. We'll handle it separately
       */
      validateStatus: () => true,
    }),
};
