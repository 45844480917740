import React from 'react';
import { Switch } from 'react-router';
import { Route, Router } from 'react-router-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import withStyles from '@material-ui/core/styles/withStyles';
import history from './history';
import theme from './theme';
import { CareersPage } from './components/careers-page';
import { NotFoundPage } from './components/not-found-page';
import { WebRoutes } from './web-routes';
import Footer from './components/footer';
import CssBaseline from '@material-ui/core/CssBaseline';

const styles = () => ({
  bodyContainer: {
    marginTop: '10px',
  },
});

const App = (props) => {
  const { classes } = props;
  return (
    <div>
      <CssBaseline />
      <MuiThemeProvider theme={theme} sheetsManager={new Map()}>
        <Router history={history}>
          <div className={classes.bodyContainer}>
            <Switch>
              <Route
                path="/"
                exact
                component={NotFoundPage}
              />
              <Route
                path={WebRoutes.NOT_FOUND}
                component={NotFoundPage}
              />
              <Route
                path={WebRoutes.CAREERS_PAGE}
                render={(props) => (
                  <CareersPage orgKey={props.match.params.orgKey} />
                )}
              />
            </Switch>
            <Footer/>
          </div>
        </Router>
      </MuiThemeProvider>
    </div>
  );
};

export default withStyles(styles)(App);
