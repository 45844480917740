// @flow

import { Api } from './api';
import { ApiRoutes } from './api-routes';

export class CustomerApiClient {
  org_key: string;

  constructor(org_key: string) {
    this.org_key = org_key;
  }

  /**
   * Prepends the customer base url
   * @private
   * @param url
   */
  route(url: string = '') {
    return `${ApiRoutes.getCustomerBaseRoute(this.org_key)}${url}`;
  }

  get() {
    return Api.get(this.route());
  }

  getJobs() {
    return Api.get(this.route(ApiRoutes.JOBS));
  }
}
