// @flow

import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { APPLY_DOMAIN, GET_TEAM_ENGINE_PAGE, IMAGE_PATH } from '../config/config';

const linkStyle = {
  color: 'gray',
  textDecoration: 'none',
};

const Footer = () => (
  <footer style={{
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#fafafa',
    opacity: 1,
    borderTop: '1px solid #cccccc',
  }}>
    <Typography variant="caption" style={{ padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span>
        © Expedition Data {(new Date()).getFullYear()} |
        <a href={`${APPLY_DOMAIN}/privacypolicy`} style={linkStyle}> Privacy Policy</a>
      </span>
      <a href={GET_TEAM_ENGINE_PAGE} style={linkStyle}>
        Powered by
        <img src={`${IMAGE_PATH}/logo/team-engine-logo-with-name-colored.png`} height="30px" alt="Team Engine" style={{ paddingLeft: '5px', verticalAlign: '-50%' }}/>
      </a>
    </Typography>
  </footer>
);

export default Footer;
