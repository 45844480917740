// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '56px',
  },
});

type Props = {
  classes: Object,
  content: React.Element<any>,
};

const NavComponent = (props: Props) => {
  const { classes, content } = props;
  return (
    <div className={classes.root}>
      <AppBar color="default">
        <Toolbar>
          {content}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export const Nav = withStyles(styles)(NavComponent);
